var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm revisionLayout",
            attrs: {
              title: "LBLREV",
              topClass: "topcolor-orange",
              isRevision: _vm.isRevision,
            },
          },
          [
            _c(
              "template",
              { slot: "card-select" },
              [
                _c("c-select", {
                  attrs: {
                    editable: !_vm.isRevision,
                    comboItems: _vm.revs,
                    type: "custom",
                    typetext: "개정이력",
                    itemText: "revisionNum",
                    itemValue: "mdmChemMaterialMsdsId",
                    label: "",
                    name: "selectedId",
                  },
                  on: { input: _vm.rowClickRev },
                  model: {
                    value: _vm.selectedId,
                    callback: function ($$v) {
                      _vm.selectedId = $$v
                    },
                    expression: "selectedId",
                  },
                }),
              ],
              1
            ),
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && !_vm.isRevision,
                          expression: "editable && !isRevision",
                        },
                      ],
                      attrs: { label: "LBLREV", icon: "restart_alt" },
                      on: { btnClicked: _vm.setRevision },
                    }),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && _vm.isRevision,
                          expression: "editable && isRevision",
                        },
                      ],
                      attrs: { label: "LBLREVCANCEL", icon: "restart_alt" },
                      on: { btnClicked: _vm.cancelRevision },
                    }),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && _vm.isRevision,
                          expression: "editable && isRevision",
                        },
                      ],
                      attrs: {
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.chemData,
                        mappingType: "POST",
                        label: "LBLSAVE",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveInfo,
                        btnCallback: _vm.saveCallback,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: _vm.editable && _vm.isRevision,
                            editable: _vm.editable && _vm.isRevision,
                            label: "LBLREVNO",
                            name: "revisionNum",
                          },
                          model: {
                            value: _vm.chemData.revisionNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "revisionNum", $$v)
                            },
                            expression: "chemData.revisionNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "LBLREVPERIOD",
                            name: "msdsRegDtStr",
                          },
                          model: {
                            value: _vm.chemData.msdsRegDtStr,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "msdsRegDtStr", $$v)
                            },
                            expression: "chemData.msdsRegDtStr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "LBLREVUSER",
                            name: "msdsRegUserName",
                          },
                          model: {
                            value: _vm.chemData.msdsRegUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "msdsRegUserName", $$v)
                            },
                            expression: "chemData.msdsRegUserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: _vm.editable && _vm.isRevision,
                            editable: _vm.editable && _vm.isRevision,
                            label: "LBLREVREASON",
                            name: "revisionContent",
                          },
                          model: {
                            value: _vm.chemData.revisionContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "revisionContent", $$v)
                            },
                            expression: "chemData.revisionContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "MSDS 내부용" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo,
                      editable: _vm.editable && _vm.revEditable,
                      label: "MSDS 내부용 첨부파일",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "MSDS 업체용" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo2,
                      editable: _vm.editable && _vm.revEditable,
                      label: "MSDS 업체용 첨부파일",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }